code-example, code-tabs {
    clear: both;
    display: block;
}

code-example {


  &:not(.no-box) {
    background-color: rgba($backgroundgray, 0.2);
    border: 0.5px solid $lightgray;
    border-radius: 5px;
    color: $darkgray;
    margin: 16px auto;
  }

  &.no-box {
    pre {
      margin: 0;
    }
    code {
      background-color: transparent;
    }
  }

  code {
    overflow: auto;
  }
}

code-example, code-tabs {
  .mat-card {
    padding: 0;
    border-radius: 5px;
  }
  code {
    overflow: auto;
  }
}

// TERMINAL / SHELL TEXT STYLES

code-example.code-shell, code-example[language=sh], code-example[language=bash] {
  background-color: $darkgray;
}

code-example header {
  background-color: $accentblue;
  border-radius: 5px 5px 0 0;
  color: $offwhite;
  font-size: 16px;
  padding: 8px 16px;
}

code-example.avoid header,
code-example.avoidFile header {
  border: 2px solid $anti-pattern;
  background: $anti-pattern;
}

code-example.avoid,
code-example.avoidFile,
code-tabs.avoid mat-tab-body,
code-tabs.avoidFile mat-tab-body {
  border: 0.5px solid $anti-pattern;
}

code-tabs div .mat-tab-body-content {
  height: auto;
}

code-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body {
  overflow-y: hidden;
}

code-tabs mat-tab-body-content .fadeIn {
  animation: opacity 2s ease-in;
}

aio-code pre {
    display: flex;
    min-height: 32px;
    margin: 16px 24px;
    white-space: pre-wrap;
    align-items: center;

    code span {
      line-height: 24px;
    }
}


.code-missing {
  color: $darkred;
}


.copy-button {
  position: absolute;
  top: -7px;
  right: -19px;
  padding: 0;

  color: $blue-grey-200;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    color: $mediumgray;
  }
}

.lang-sh .copy-button, .lang-bash .copy-button {
  color: $mediumgray;
  &:hover {
    color: $lightgray;
  }
}

.code-tab-group .mat-tab-label {
  &:hover {
    background: rgba(black, 0.04);
  }
  white-space: nowrap;
}

.code-tab-group .mat-tab-body-content {
  height: auto;
  transform: none;
}


[role="tabpanel"] {
    transition: none;
}

.sidenav-content code a {
  color: inherit;
  font-size: inherit;
}

/* PRETTY PRINTING STYLES for prettify.js. */

.prettyprint {
  position: relative;
}

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin: 0;
  font-family: $main-font;
  color: #B3B6B7;
  li {
    margin: 0;
    font-family: $code-font;
    font-size: 90%;
    line-height: 24px;
  }
}

/* The following class|color styles are derived from https://github.com/google/code-prettify/blob/master/src/prettify.css*/

/* SPAN elements with the classes below are added by prettyprint. */
.pln { color: #000 }  /* plain text */

@media screen {
  .str { color: #800 }  /* string content */
  .kwd { color: #00f }  /* a keyword */
  .com { color: #060 }  /* a comment */
  .typ { color: red }  /* a type name */
  .lit { color: #08c }  /* a literal value */
  /* punctuation, lisp open bracket, lisp close bracket */
  .pun, .opn, .clo { color: #660 }
  .tag { color: #008 }  /* a markup tag name */
  .atn { color: #606 }  /* a markup attribute name */
  .atv { color: #800 }  /* a markup attribute value */
  .dec, .var { color: #606 }  /* a declaration; a variable name */
  .fun { color: red }  /* a function name */
}

/* Use higher contrast and text-weight for printable form. */
@media print, projection {
  .str { color: #060 }
  .kwd { color: #006; font-weight: bold }
  .com { color: #600; font-style: italic }
  .typ { color: #404; font-weight: bold }
  .lit { color: #044 }
  .pun, .opn, .clo { color: #440 }
  .tag { color: #006; font-weight: bold }
  .atn { color: #404 }
  .atv { color: #060 }
}

/* SHELL / TERMINAL CODE BLOCKS */

code-example.code-shell, code-example[language=sh], code-example[language=bash] {
  .pnk, .blk, .pln, .otl, .kwd, .typ, .tag, .str, .atv, .atn, .com, .lit, .pun, .dec {
    color: $codegreen;
  }
}
